import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import photo from './Assets/4_angles.png';
import { resizeImage } from "./utility/image-resize";
import { ImageSendingAPIs } from "./utility/api";

const MAX_WIDTH = 1101;
const MAX_HEIGHT = 921;
const MAX_LENGTH = 250000;

const redirectUrl = "https://sitav4-website.dev.alix.xantav.com/?provider=COGNITO"

const angles = [
    { id: 1, label: "Front View", reference: photo, camera: "FRONT_CAMERA" },
    { id: 2, label: "45° Left", reference: photo, camera: "LEFT_CAMERA" },
    { id: 3, label: "45° Right", reference: photo, camera: "REAR_CAMERA" },
    { id: 4, label: "Side Left", reference: photo, camera: "RIGHT_CAMERA" },
    { id: 5, label: "Side Right", reference: photo, camera: "FRONT_CAMERA" },
    { id: 6, label: "Top View", reference: photo, camera: "LEFT_CAMERA" },
    { id: 7, label: "Bottom View", reference: photo, camera: "REAR_CAMERA" },
    { id: 8, label: "Back View", reference: photo, camera: "RIGHT_CAMERA" },
];

function CameraApp() {
    const [capturingEnabled, setCapturingEnabled] = useState(false);
    const [currentAngle, setCurrentAngle] = useState(0);
    const [capturedImages, setCapturedImages] = useState(Array(8).fill(null));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRecaptureMode, setIsRecaptureMode] = useState(false);
    const [facingMode, setFacingMode] = useState('environment'); // Default to front camera
    const [hasMultipleCameras, setHasMultipleCameras] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [cameraReady, setCameraReady] = useState(false);
    const [error, setError] = useState('No error');
    const [openNotification, setOpenNotification] = useState(false);
    const [deviceIds, setDeviceIds] = useState({ front: null, back: null });

    const webcamRef = useRef(null);


    useEffect(() => {
        getCameraDevices();
    }, []);
    // Match reference image height to the camera's height once the camera is ready
    useEffect(() => {

        const adjustHeight = () => {
            const cameraElement = document.getElementById("camera");
            const referenceImageElement = document.getElementById("ref-image");
            if (cameraElement && referenceImageElement) {
                referenceImageElement.style.height = `${cameraElement.clientHeight}px`;
                // console.log("Camera ,", cameraElement.clientHeight, referenceImageElement.style.height)
            }
        };

        // Call adjustHeight after camera is ready
        setTimeout(() => {
            // console.log("End after 2 seconds");
            adjustHeight();
        }, 100);


        // Add event listener to adjust on window resize
        window.addEventListener('resize', adjustHeight);

        return () => {
            window.removeEventListener('resize', adjustHeight);
        };

    }, [cameraReady]);

    const getCameraDevices = async () => {
        try {
            // Try to get a video stream to trigger permission request
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            // Once stream is received, enumerate devices
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const videoDevices = devices.filter(device => device.kind === 'videoinput');
                    setCameras(videoDevices);
                    // console.log(videoDevices)


                    if (videoDevices.length > 1) {
                        setCurrentDevice(videoDevices[1].deviceId);
                        setHasMultipleCameras(true);
                    }
                    else if (videoDevices.length > 0) {
                        setCurrentDevice(videoDevices[0].deviceId);
                    }
                });

            // Stop the stream after getting devices
            stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            console.error("Access denied or not available", error);
        }
    };
    // Handle camera selection change
    const handleCameraChange = (deviceId) => {
        setCurrentDevice(deviceId);
    };

    const switchCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
        handleCameraChange(prevDevice =>
            prevDevice === deviceIds.back ? deviceIds.front : deviceIds.back
        );

    };

    // Capture an image
    const captureImage = async () => {
        const imageSrc = webcamRef.current.getScreenshot();

        // Convert the base64 string to a Blob and then resize it
        const imageBlob = await (await fetch(imageSrc)).blob();

        // Resize the image if it's greater than 480KB
        const resizedImageSrc = await resizeImage(imageBlob, MAX_WIDTH, MAX_HEIGHT, MAX_LENGTH);

        console.log("Final length: " + resizedImageSrc.length)
        const updatedImages = [...capturedImages];
        updatedImages[currentAngle] = { angle: angles[currentAngle], image: resizedImageSrc };
        setCapturedImages(updatedImages);

        if (updatedImages.every((img) => img !== null)) {
            closeModal() // Close modal after capturing all images
        } else {
            setCurrentAngle((prev) => (prev + 1) % angles.length);
        }
    };

    // Open the modal for capturing an image or recapturing
    const openModal = (index) => {
        setCurrentAngle(index);
        setIsModalOpen(true);
        setCapturingEnabled(true);

    };
    const closeModal = (index) => {
        setCapturingEnabled(false);
        setIsModalOpen(false);
        setCameraReady(false);
    }

    // Recapture or start capturing images again
    const startCapturing = () => {
        setCapturedImages(Array(8).fill(null)); // Reset captured images
        setCapturingEnabled(true);
        setIsModalOpen(true);
        setCurrentAngle(0);
    };

    // Confirm image
    const confirmImage = () => {
        setIsModalOpen(false);
        if (capturedImages.every((img) => img !== null)) {
            setCapturingEnabled(false);
        }
    };
    const sendImage = async (e) => {
        e.preventDefault();

        // console.log("clicked ")
        document.getElementById(e.target.id).disabled = true
        document.getElementById(e.target.id).children.item(0).className = "loader"
        if (capturedImages.every((img) => img === null)) {

        }
        else {
            var timestamp = new Date().toISOString();
            var ref = new Date().toISOString();
            var successCount = 0;
            for (var i = 0; i < 4; i++) {
                document.getElementById(e.target.id).disabled = true
                document.getElementById(e.target.id).children.item(0).className = "loader"
                //                ############################ Structure ###################################################################
                //                #   capturedImages[i] = { "angle": "{ id: 1, label: "Front View", reference: photo }", "image": "" }
                //                #   data type {img1:capturedImages[i],img2:capturedImages[i] }
                //                #   i=0 , i*2 = 0, i*2+1 = 1 ;#### i=1 , i*2 = 2, i*2+1 = 3 ;#### i=2 , i*2 = 4, i*2+1 = 5 ;##### i=3 , i*2 = 6, i*2+1 = 7
                //                #   ;
                //                ###########################################################################################################
                var utlId = 1;

                if (i == 0 || i == 2) {
                    utlId = 2;
                }
                var requestNumber = 0;
                if (i == 2) {
                    timestamp = new Date().toISOString();
                }

                var data = { img1: capturedImages[i * 2], img2: capturedImages[i * 2 + 1], utlId: utlId, requestNumber: requestNumber, timestamp: timestamp, ref: ref }

                // var data = { img1: capturedImages[i * 2], img2: capturedImages[i * 2 + 1] }
                var response = await ImageSendingAPIs().sendImage(data);
                // console.log("message error: ", response)
                if (response.status == 'error') {
                    document.getElementById(e.target.id).disabled = false
                    document.getElementById(e.target.id).children.item(0).className = ""
                    setOpenNotification(true);
                    setError(response.message)
                }
                else {
                    document.getElementById(e.target.id).disabled = false
                    document.getElementById(e.target.id).children.item(0).className = ""
                    successCount++;
                }

                if (successCount == 4) {
                    window.location.href = redirectUrl;
                    console.log("redirecting to .... ", redirectUrl)
                }
            }
        }
    }









    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {!capturingEnabled ? (
                <button onClick={startCapturing} style={{ margin: "80px", width: '210px', fontSize: '24px', padding: '20px' }}>
                    {capturedImages.every((img) => img !== null) ? "Recapture All" : "Start Capturing"}
                </button>
            ) : null}

            {/* Display Captured Images */}
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", justifyContent: 'center' }}>
                {capturedImages.map((imageData, index) => (
                    <div key={index} style={{ margin: "10px" }}>
                        <div style={{ border: "1px solid black", padding: "10px" }}>
                            <h4>{angles[index].camera}</h4>
                            {imageData ? (
                                <img
                                    src={imageData.image}
                                    alt={`Captured ${index}`}
                                    width="300px"
                                    height="200px"
                                    onClick={() => openModal(index)} // Click to open modal for recapture
                                    style={{ cursor: "pointer" }}
                                />
                            ) : (
                                <div
                                    style={{ width: "300px", height: "200px", backgroundColor: "#f0f0f0", cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => openModal(index)}
                                >
                                    <p>No image</p>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {isModalOpen && (<div>
                {angles[currentAngle].id === 5 && (<h2 style={{marginBottom:'0px'}}>Please Flip the bag</h2>)}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    

                    <div style={{ flex: "0 0 47.5%", padding: "10px" }}>
                        <h2>{angles[currentAngle].camera}</h2>

                        <div

                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: 'column'

                            }}
                        >
                            <Webcam
                                id="camera"
                                audio={false}
                                ref={webcamRef}
                                onUserMedia={() => setCameraReady(true)}
                                screenshotFormat="image/jpeg"
                                forceScreenshotSourceSize
                                videoConstraints={{
                                    facingMode: facingMode,
                                    width: { ideal: MAX_WIDTH },
                                    height: { ideal: MAX_HEIGHT },
                                }}
                                style={{ width: "100%", height: "100%" }}
                            />


                            {/* {hasMultipleCameras && (
                                <button onClick={switchCamera} style={{ marginTop: "-80px" }}>
                                    Switch Camera
                                </button>
                            )} */}
                            {/* {cameras.map((camera) => (
                                <button key={camera.deviceId} onClick={() => handleCameraChange(camera.deviceId)}>
                                    Use {camera.label || 'Camera'}
                                </button>
                            ))} */}

                        </div>
                    </div>

                    <div style={{ flex: "0 0 47.5%", padding: "10px" }}>
                        <h2>Reference Image</h2>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                            }}
                        >
                            <img
                                id="ref-image"
                                src={angles[currentAngle].reference}
                                alt="Reference"
                                style={{ width: "100%", height: "980px", objectFit: "contain" }}
                            />
                        </div>
                    </div>
                </div>
                {cameraReady && (<div style={{ gap: '40px', display: 'flex', flexDirection: 'row', width: '80%', marginLeft: '80px', marginBottom: '20px' }}>
                    <button onClick={captureImage}>Capture</button>
                    <button onClick={closeModal}>Close</button>
                </div>)}

            </div>)
            }

            {capturedImages.every((img) => img !== null) &&
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>

                    <button id="sendBtn" onClick={sendImage} style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', marginRight: "80px", marginTop: '200px', marginBottom: '50px' }}>
                        Confirm & Send
                        <span className=""></span>
                    </button>

                </div>}


            {openNotification && (<div style={{ gap: '40px', position: 'fixed', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', top: "-125px" }}>
                <div style={{ backgroundColor: 'white', width: '50vw', maxHeight: '40vh', position: 'relative', borderRadius: '8px', marginBottom: '80px' }}>
                    {/* Close button fixed at top right corner */}
                    <p onClick={() => setOpenNotification(false)} style={{ color: 'red', position: 'absolute', right: '10px', cursor: 'pointer' }}>
                        Close
                    </p>

                    {/* Content div with scrollY and word break */}
                    <div style={{ padding: '40px', borderRadius: '8px', overflowY: 'auto', wordBreak: 'break-word', maxHeight: 'calc(40vh )', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                        <p style={{ color: 'black', fontSize: '24px' }}>Error:</p>
                        <span style={{ color: 'black' }}>
                            {error}
                        </span>
                    </div>
                </div>
            </div>
            )}

        </div>
    );
}

export default CameraApp;
