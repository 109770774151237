                
export function resizeImage(imageBlob, maxWidth, maxHeight, maxSize) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imageBlob);

        fileReader.onload = (event) => {
            const imgElement = new Image();
            imgElement.src = event.target.result;

            imgElement.onload = () => {
                const canvas = document.createElement('canvas');
                let quality = 1.0; // Initial quality for compression
                console.log("imgElement.width: " + imgElement.width);
                console.log("imgElement.height: " + imgElement.height);
                //const MAX_HEIGHT = imgElement.height;


                // Target dimensions for the image
                canvas.width = imgElement.width;
                canvas.height = imgElement.height;
                
                // Set canvas dimensions equal to the image
                if (imgElement.width > maxWidth) {
                    let targetHeight = parseFloat(maxWidth) * parseFloat(imgElement.height) / parseFloat(imgElement.width);
   	
                    canvas.width = maxWidth;
                    canvas.height = targetHeight;
                }
                if (imgElement.height > maxHeight) {
                    let targetWidth = parseFloat(maxHeight) * parseFloat(imgElement.width) / parseFloat(imgElement.height);
   	
                    canvas.width = targetWidth;
                    canvas.height = maxHeight;
                }
                console.log("Updated width : " + canvas.width);
                console.log("Updated height : " + canvas.height);

                const ctx = canvas.getContext('2d');
                ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

                // Reduce quality until image size is less than or equal to 480KB
                function compressImage() {
                    const compressedImage = canvas.toDataURL('image/jpeg', quality);
                    const sizeInBytes = atob(compressedImage.split(',')[1]).length;
                    console.log("Quality: " + quality + ", size in bytes-> ",sizeInBytes);

                    if (sizeInBytes > maxSize && quality > 0.05) {
                        // Reduce the quality and try again
                        quality -= 0.05;
                        compressImage();
                    } else {
                        resolve(compressedImage); // Return the compressed image as Base64
                    }
                }

                compressImage();
            };

            imgElement.onerror = (error) => reject(error);
        };

        fileReader.onerror = (error) => reject(error);
    });
}
