import logo from './logo.svg';
import './App.css';
import CameraApp from './CameraApp';
import Navbar from './components/Navbar';
import SingleImage from './SingleImage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<CameraApp />} />
          <Route path="/a" element={<SingleImage />} />
        </Routes>
      </Router>
      
      
    </div>
  );
}

export default App;
