import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import photo from './Assets/4_angles.png';
import { resizeImage } from "./utility/image-resize";
import { ImageSendingAPIs } from "./utility/api";

const SINGLE_MAX_WIDTH = 1024;
const SINGLE_MAX_HEIGHT = 1024;
const SINGLE_MAX_LENGTH = 150000;

const redirectUrl = "https://mikv4-website.dev.alix.xantav.com/?provider=IDEMIA"

const angles = [
    { id: 1, label: "Capture Image", reference: photo, camera: "FRONT_CAMERA" },

];

function SingleImage() {
    const [capturingEnabled, setCapturingEnabled] = useState(false);
    const [currentAngle, setCurrentAngle] = useState(0);
    const [capturedImages, setCapturedImages] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRecaptureMode, setIsRecaptureMode] = useState(false);
    const [facingMode, setFacingMode] = useState('environment'); // Default to front camera
    const [hasMultipleCameras, setHasMultipleCameras] = useState(false);
    const [cameras, setCameras] = useState([]);
    const [currentDevice, setCurrentDevice] = useState(null);
    const [cameraReady, setCameraReady] = useState(false);
    const [error, setError] = useState('No error');
    const [openNotification, setOpenNotification] = useState(false);
    const [deviceIds, setDeviceIds] = useState({ front: null, back: null });
    const [apiResponse, setApiResponse] = useState(null)

    const webcamRef = useRef(null);


    const [tableData, setTableData] = useState([]);

    useEffect(() => {

    }, []);


    useEffect(() => {
        getCameraDevices();
    }, []);
    // Match reference image height to the camera's height once the camera is ready
    useEffect(() => {

        const adjustHeight = () => {
            const cameraElement = document.getElementById("camera");
            const referenceImageElement = document.getElementById("ref-image");
            if (cameraElement && referenceImageElement) {
                referenceImageElement.style.height = `${cameraElement.clientHeight}px`;
                // console.log("Camera ,", cameraElement.clientHeight, referenceImageElement.style.height)
            }
        };

        // Call adjustHeight after camera is ready
        setTimeout(() => {
            // console.log("End after 2 seconds");
            adjustHeight();
        }, 100);


        // Add event listener to adjust on window resize
        window.addEventListener('resize', adjustHeight);

        return () => {
            window.removeEventListener('resize', adjustHeight);
        };

    }, [cameraReady]);

    const getCameraDevices = async () => {
        try {
            // Try to get a video stream to trigger permission request
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            // Once stream is received, enumerate devices
            navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                    const videoDevices = devices.filter(device => device.kind === 'videoinput');
                    setCameras(videoDevices);
                    // console.log(videoDevices)


                    if (videoDevices.length > 1) {
                        setCurrentDevice(videoDevices[1].deviceId);
                        setHasMultipleCameras(true);
                    }
                    else if (videoDevices.length > 0) {
                        setCurrentDevice(videoDevices[0].deviceId);
                    }
                });

            // Stop the stream after getting devices
            stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            console.error("Access denied or not available", error);
        }
    };
    // Handle camera selection change
    const handleCameraChange = (deviceId) => {
        setCurrentDevice(deviceId);
    };

    const switchCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
        handleCameraChange(prevDevice =>
            prevDevice === deviceIds.back ? deviceIds.front : deviceIds.back
        );

    };

    // Capture an image
    const captureImage = async () => {
        const imageSrc = webcamRef.current.getScreenshot();

        // Convert the base64 string to a Blob and then resize it
        const imageBlob = await (await fetch(imageSrc)).blob();

        // Resize the image if it's greater than 480KB
        const resizedImageSrc = await resizeImage(imageBlob, SINGLE_MAX_WIDTH, SINGLE_MAX_HEIGHT, SINGLE_MAX_LENGTH);
        console.log(resizedImageSrc.length)

        setCapturedImages({ image: resizedImageSrc });
        closeModal()

    };

    // Open the modal for capturing an image or recapturing
    const openModal = (index) => {
        setCurrentAngle(index);
        setIsModalOpen(true);
        setCapturingEnabled(true);

    };
    const closeModal = (index) => {
        setCapturingEnabled(false);
        setIsModalOpen(false);
        setCameraReady(false);
    }

    // Recapture or start capturing images again
    const startCapturing = () => {
        setCapturedImages(null); // Reset captured images
        setCapturingEnabled(true);
        setIsModalOpen(true);
        setCurrentAngle(0);
        setApiResponse(null);
    };

    // Confirm image
    const confirmImage = () => {
        setIsModalOpen(false);
        if (capturedImages.every((img) => img !== null)) {
            setCapturingEnabled(false);
        }
    };
    const sendImage = async (e) => {
        e.preventDefault();

        // console.log("clicked ")
        document.getElementById(e.target.id).disabled = true
        document.getElementById(e.target.id).children.item(0).className = "loader"
        if (capturedImages === null) {

        }
        else {
            var data = capturedImages

            // var data = { img1: capturedImages[i * 2], img2: capturedImages[i * 2 + 1] }
            var response = await ImageSendingAPIs().sendSingleImage(data);

            // console.log("message error: ", response)
            if (response.status == 'error') {
                document.getElementById(e.target.id).disabled = false
                document.getElementById(e.target.id).children.item(0).className = ""
                setOpenNotification(true);
                setError(response.message)
            }
            else {
                document.getElementById(e.target.id).disabled = false
                document.getElementById(e.target.id).children.item(0).className = ""
                setApiResponse(response)
                let data = response.data;
                const transformedData = [
                    ["Type", data.response["Type Code"], data.response["Type Description"]],
                    ["Colour", data.response["Colour Code"], data.response["Colour Description"]],
                    ["Material", data.response["Material Code"], data.response["Material Description"]],
                    ["Description", data.response["Description Code"], data.response["Description Description"]],
                    ["Damage", data.response["Damage Status"], data.response["Damage Description"]],
                    ["Size", data.response["Size Code"], data.response["Size Description"]],
                    ["Shape", data.response["Shape Code"], data.response["Shape Description"]],
                    ["Closure", data.response["Closure Code"], data.response["Closure Description"]]
                ];

                setTableData(transformedData);

            }
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {!capturingEnabled ? (
                <button onClick={startCapturing} style={{ margin: "80px", width: '210px', fontSize: '24px', padding: '20px' }}>
                    {capturedImages !== null ? "Recapture" : "Capture Image"}
                </button>
            ) : null}

            {/* Display Captured Images */}
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", justifyContent: 'center' }}>

                <div key={1} style={{ margin: "10px" }}>
                    <div style={{ border: "1px solid black", padding: "10px" }}>
                        <h4>Bag Picture</h4>
                        {capturedImages ?(
                            <img
                                src={capturedImages.image}
                                alt={`Captured ${1}`}
                                width="75%"
                                //height="25%"
                                onClick={() => openModal(1)} // Click to open modal for recapture
                                style={{ cursor: "pointer" }}
                            />
                        ) : (
                            <div
                                style={{ width: "300px", height: "200px", backgroundColor: "#f0f0f0", cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => openModal(1)}
                            >
                                <p>No image</p>
                            </div>
                        )}
                    </div>
                </div>

            </div>

            {isModalOpen && (<div>

                <div style={{ display: "flex", justifyContent: "center" }}>


                    <div style={{ flex: "0 0 47.5%", padding: "10px" }}>
                        <h2>Please take a picture</h2>

                        <div

                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: 'column'

                            }}
                        >
                            <Webcam
                                id="camera"
                                audio={false}
                                ref={webcamRef}
                                onUserMedia={() => setCameraReady(true)}
                                screenshotFormat="image/jpeg"
                                forceScreenshotSourceSize
                                videoConstraints={{
                                    facingMode: facingMode,
                                    width: { ideal: SINGLE_MAX_WIDTH },
                                    height: { ideal: SINGLE_MAX_HEIGHT },
                                }}
                                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                            />

                        </div>
                    </div>

                </div>
                {cameraReady && (<div style={{ gap: '40px', display: 'flex', flexDirection: 'row', width: '80%', marginLeft: '80px', marginBottom: '20px' }}>
                    <button onClick={captureImage}>Capture</button>
                    <button onClick={closeModal}>Close</button>
                </div>)}

            </div>)
            }

            {capturedImages !== null &&
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>

                    <button id="sendBtn" onClick={sendImage} style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', marginRight: "80px", marginTop: '50px', marginBottom: '50px' }}>
                        Confirm & Send
                        <span className=""></span>
                    </button>

                </div>}


            {openNotification && (<div style={{ gap: '40px', position: 'fixed', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', top: "-125px" }}>
                <div style={{ backgroundColor: 'white', width: '50vw', maxHeight: '40vh', position: 'relative', borderRadius: '8px', marginBottom: '80px' }}>
                    {/* Close button fixed at top right corner */}
                    <p onClick={() => setOpenNotification(false)} style={{ color: 'red', position: 'absolute', right: '10px', cursor: 'pointer' }}>
                        Close
                    </p>

                    {/* Content div with scrollY and word break */}
                    <div style={{ padding: '40px', borderRadius: '8px', overflowY: 'auto', wordBreak: 'break-word', maxHeight: 'calc(40vh )', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                        <p style={{ color: 'black', fontSize: '24px' }}>Error:</p>
                        <span style={{ color: 'black' }}>
                            {error}
                        </span>
                    </div>
                </div>
            </div>)}

            {apiResponse !== null && (<div className="table-container" style={{ color: 'black', width: '90%' }}>
                <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-start' }}>
                </div>
                <table className="styled-table" border="1">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Code</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td>{row[0]}</td>
                                <td>{row[1]}</td>
                                <td>{row[2]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>)}
        </div>
    );
}

export default SingleImage;
