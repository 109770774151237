import React from 'react'
import { useNavigate } from 'react-router-dom';
import home from '../Assets/home.svg'
import photo from '../Assets/IDEMIA_logo.png'

const Navbar = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };
  const moveToSingleImagePage = () => {
    navigate('/a');
  }

  return (
    <div className='nav-container'>
      <img alt='logo_sita' src='https://upload.wikimedia.org/wikipedia/commons/c/ce/Sita_%28IT-Unternehmen%29_logo.svg' style={{ height: '50%',cursor:'pointer' }} onClick={handleClick} />
      <img alt='logo_idemia' src={photo} style={{ height: '100%',cursor:'pointer' }} onClick={moveToSingleImagePage} />
    </div>
  )
}

export default Navbar