import { useCallback } from 'react';
import axios from 'axios';

const API_URL = 'https://sitav4-arch.dev.alix.xantav.com';  // Replace with actual API URL
const CLASSIFY_BAG_URL= 'https://alix-mobile-ws.dev.innolab.xantav.com'

export const ImageSendingAPIs = () => {
    const sendImage = async (data) => {


        //                #   capturedImages[i] = { "angle": "{ id: 1, label: "Front View", reference: photo }", "image": "" }
        //                #   data type {img1:capturedImages[i],img2:capturedImages[i] }

        var dataToSend = {
            "arch_id": "ARCH005",
            "images_list": [
                {
                    "cameraPosition": data.img1.angle.camera,
                    "image": data.img1.image.split("base64,")[1]
                },
                {
                    "cameraPosition": data.img2.angle.camera,
                    "image": data.img2.image.split("base64,")[1]
                }
            ],
            "request_count": 1,
            "request_num": data.requestNumber,
            "timestamp": data.timestamp,
            "ref_uid": data.ref,
            "utl_id": data.utlId,
            "utlImagesListLength": 2

        }

        try {
            console.log("request sent ", dataToSend)
            const response = await axios.post(`${API_URL}/bagimages`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            console.log("response ", response)

            return response.data;

        } catch (error) {
            console.error('Error in sending:', error);
            return { 'status': 'error', 'message': error.message };
        }
    };
    const sendSingleImage = async (data) => {
        var dataToSend = {
            "image": {
                "dataBinary": data.image.split("base64,")[1]
            }
        }
        console.log("sendSingleImage ", dataToSend)
        try {
            const response = await axios.post(`${CLASSIFY_BAG_URL}/classifyBag`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            console.log("response ", response)


            return response;

        } catch (error) {
            console.error('Error in sending', error);
            return { 'status': 'error', 'message': error.message };
        }
    }

    return { sendImage,sendSingleImage }
}